import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "./Footer";
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  Button,
  Toolbar,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Grow,
  Container,
  Box
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/styles";
import WaSeo from "./shared/WaSeo";
import useScreensize from "../hooks/useScreensize";
import MenuUpIcon from "mdi-react/MenuUpIcon";
import { grey } from "@material-ui/core/colors";
import clsx from "clsx";
import Logo from "../assets/Logo_Wahl_Oktober21.png";
import { useScrollPosition } from "../hooks/useScrollPosition";

const drawerWidth = 280;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      flexShrink: 0,
      zIndex: 9999999
    }
  },
  appBar: {
    position: "fixed",
    marginLeft: drawerWidth,
    zIndex: 999,
    background: "transparent",
    transition: "all 0.3s",
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5)
    },
    [theme.breakpoints.down("xs")]: {
      background: "#fff"
    }
  },
  appBarAnimated: {
    background: "#fff",
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(0)
    }
  },
  menuButton: {
    color: grey[800]
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    zIndex: 9999,
    width: drawerWidth,
    background: "white",
    color: grey[600]
  },
  content: {
    flexGrow: 1,
    padding: 0,
    background: "url(" + Logo + ") no-repeat center 40vh",
    backgroundSize: "20vw auto",
  },
  toolbarContent: {
    [theme.breakpoints.up("md")]: {
      transition: "all 0.3s"
    }
  },
  toolbarContentAnimated: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 0
    }
  },
  logo: {
    height: "80%",
    width: "auto"
  },
  logoWrapper: {
    height: "100%",
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(0),
      marginRight: "auto",
      padding: theme.spacing(1, 0)
    },
    [theme.breakpoints.up("md")]: {
      opacity: 0,
      transition: "all 0.3s"
    }
  },
  logoWrapperAnimated: {
    [theme.breakpoints.up("md")]: {
      opacity: 1
    }
  },
  appBarMenu: {
    flexGrow: 1
  },
  tb: {
    height: 1,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  btnBeratung: {
    marginRight: "15px"
  },
  desktopMenuIcon: {
    marginLeft: "auto",
    marginRight: theme.spacing(2)
  },
  popover: {
    zIndex: 99999999,
    marginTop: theme.spacing(3)
  },
  popoverPaper: {
    background: "white",
    minWidth: 600
  },
  menuUpIcon: {
    position: "absolute",
    right: 0,
    top: -30,
    width: 50,
    height: 50,
    color: "white"
  },
  menuImage: {
    backgroundSize: "cover"
  },
  menuItem: {
    borderRadius: 4
  },
  activeMenuItem: {
    background: grey[100]
  },
  menuItemButton: {
    fontWeight: 600,
    minWidth: 0,
    margin: theme.spacing(0, 3)
  },
  drawerLogo: {
    height: "auto",
    width: 160
  }
}));

const Layout = ({ children, refs }) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [desktopOpen, setDesktopOpen] = useState(false);
  const [settings, setSettings] = useState(null);
  const theme = useTheme();
  const anchorRef = React.useRef(null);
  const { xs, sm, md, lg, xl } = useScreensize();
  const [activePath, setActivePath] = useState(null);
  const location = useLocation();
  const [animateOnScroll, setAnimateOnScroll] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isAnimated = currPos.y < -20;
      if (isAnimated && !animateOnScroll) setAnimateOnScroll(true);
      if (!isAnimated && animateOnScroll) setAnimateOnScroll(false);
    },
    [animateOnScroll]
  );

  const menuItems = [
    {
      title: "Qualität",
      href: "/#qualitaet"
    },
    {
      title: "Handwerk",
      href: "/#handwerk"
    },
    {
      title: "Service",
      href: "/#service"
    },
    {
      title: "Innovation",
      href: "/#innovation"
    },
    {
      title: "Über uns",
      href: "/#ueber-uns"
    },
    {
      title: "Impressum",
      href: "/impressum"
    },
    {
      title: "Datenschutz",
      href: "/datenschutz"
    }
  ];

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDesktopClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setDesktopOpen(false);
  };

  const menu = (
    <MenuList>
      {menuItems.map((item, index) => (
        <MenuItem
          className={clsx(classes.menuItem, {
            [classes.activeMenuItem]: item.path === activePath
          })}
          button
          key={index}
          component={item.action ? undefined : item.href ? "a" : Link}
          to={item.action || item.href ? undefined : item.path}
          href={item.href ? item.href : undefined}
          onClick={event => {
            setMobileOpen(false);
            if (item.action) {
              item.action();
            }
          }}
        >
          {item.title}
        </MenuItem>
      ))}
    </MenuList>
  );

  const secondaryMenu = Boolean(settings) && (
    <MenuList>
      {settings.secondary_menu.map((item, index) => (
        <MenuItem
          className={clsx(classes.menuItem, {
            [classes.activeMenuItem]: item.value.url === activePath
          })}
          button
          key={index}
          component={Link}
          to={item.value.url}
          onClick={event => {
            setMobileOpen(false);
            handleDesktopClose(event);
          }}
        >
          {item.value.title}
        </MenuItem>
      ))}
    </MenuList>
  );

  const drawer = (
    <div>
      <a href="/">
        <Box p={2}>
          <img src={Logo} alt="" className={classes.drawerLogo} />
        </Box>
      </a>
      <Divider />
      {menu}
      <Divider />
      {secondaryMenu}
    </div>
  );

  const seo = Boolean(settings) && (
    <WaSeo title={settings.title} description={settings.description} />
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(
          classes.appBar,
          animateOnScroll && classes.appBarAnimated
        )}
        elevation={animateOnScroll || xs ? 4 : 0}
      >
        <Toolbar className={classes.tb}>
          <Container maxWidth="xl">
            <Box
              display="flex"
              height={xs ? 56 : 64}
              alignItems="center"
              className={clsx(
                classes.toolbarContent,
                animateOnScroll && classes.toolbarContentAnimated
              )}
            >
              <Link
                className={clsx(
                  classes.logoWrapper,
                  animateOnScroll && classes.logoWrapperAnimated
                )}
                to="/"
              >
                <img src={Logo} alt="" className={classes.logo} />
              </Link>
              <Button
                color="inherit"
                aria-label="Open drawer"
                edge="end"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                Menü <MenuIcon />
              </Button>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="Mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden lgUp implementation="css">
          <Drawer
            style={{width: "300px"}}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>{children}</main>
      {desktopOpen && (
        <Popper
          className={classes.popover}
          open={desktopOpen}
          anchorEl={anchorRef.current}
          keepMounted
          transition
          disablePortal
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} in={desktopOpen}>
              <Paper id="menu-list-grow" className={classes.popoverPaper}>
                <MenuUpIcon className={classes.menuUpIcon} />
                <ClickAwayListener onClickAway={handleDesktopClose}>
                  <Box display="flex" p={4}>
                    <Box flexGrow={1} flexShrink={0} flexBasis="1px" mr={3}>
                      {menu || <></>}
                    </Box>
                    <Box width="1px" bgcolor={grey[200]} mr={3} />
                    <Box flexGrow={1} flexShrink={0} flexBasis="1px" px={2}>
                      {secondaryMenu || <></>}
                    </Box>
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
      <Footer settings={settings} />
      {seo}
    </div>
  );
};

export default Layout;
