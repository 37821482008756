import React from "react";
import { Typography } from "@material-ui/core";

const Page = () => {
  return (
    <>
      <Typography variant="h1">Page</Typography>
    </>
  );
};

export default Page;
