import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React, { useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import WaSlider from "./shared/WaSlider";
import Slide1 from "../assets/ueberuns/1.jpg";
import Slide2 from "../assets/ueberuns/2.jpg";
import Slide3 from "../assets/ueberuns/3.jpg";
import Slide4 from "../assets/ueberuns/4.jpg";
import Slide5 from "../assets/ueberuns/5.jpg";
import useScreensize from "../hooks/useScreensize";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    zIndex: 1,
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    background: grey[200],
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    }
  },
  title: {
    width: "100%",
    color: grey[900],
    [theme.breakpoints.up("md")]: {
      width: "130%",
    }
  },
  subtitle: {
    color: grey[900]
  },
  mainText: {
    color: grey[900]
  },
  arrow: {
    width: 40,
    height: 40
  },
  slideImg: {
    width: "100%",
    height: "auto"
  }
}));

const images = [Slide1, Slide2, Slide3, Slide4, Slide5, Slide1, Slide2, Slide3, Slide4, Slide5];
const texts = [];

const SectionUeberUns = () => {
  const classes = useStyles();
  const [slideIndex, setSlideIndex] = useState({ from: 0, to: 0 });
  const { xs, sm, md, lg, xl } = useScreensize();

  const next = () => {
    let { to } = slideIndex;
    const fromNew = to;
    if (to >= imageSlides.length - 1) {
      to = 0;
    } else {
      to += 1;
    }
    setSlideIndex({ from: fromNew, to });
  };

  const prev = () => {
    let { to } = slideIndex;
    const fromNew = to;
    if (to <= 0) {
      to = imageSlides.length - 1;
    } else {
      to -= 1;
    }
    setSlideIndex({ from: fromNew, to });
  };

  const imageSlides = images.map((img, i) => (
    <img src={img} alt="" className={classes.slideImg} />
  ));

  const textSlides = texts.map((t, i) => (
    <React.Fragment key={i}>
      <Typography variant="h3">{t.title}</Typography>
      <Box mt={2}>
        <Typography variant="body1">{t.text}</Typography>
      </Box>
    </React.Fragment>
  ));

  return (
    <div className={classes.wrapper} id="ueber-uns">
      <Box display={xs || sm ? "block" : "flex"}>
        <Box width={xs || sm ? "100%" : "55%"} overflow="hidden">
          <Box width="120%" style={{marginLeft: "-20%"}}>
            <WaSlider
              slideIndex={slideIndex}
              slides={imageSlides}
              slidesToShow={2}
            />
          </Box>
          <Box width="100%">
            <WaSlider
              slideIndex={slideIndex}
              slides={textSlides}
              slidesToShow={1}
            />
            <Box textAlign="right">
              <IconButton color="inherit" onClick={prev}>
                <ArrowBackIcon className={classes.arrow} />
              </IconButton>
              <IconButton color="inherit" onClick={next}>
                <ArrowForwardIcon className={classes.arrow} />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box width={xs || sm ? "100%" : "45%"} pr={{xs: 6, md: "13vw"}} pl={6} zIndex={9}>
          <Typography variant="h1" component="h2" className={classes.title} gutterBottom>
            Über uns
          </Typography>
          <Box mt={3}>
            <Typography variant="body1" className={classes.mainText}>
            Unser Optikerbetrieb ist ein Familienunternehmen, das
ich seit 2015 mit meiner Frau führe.<br /><br />
Die Ausbildung begann ich 1986 bei der Fa. Diehl in Kaiserslautern und bin somit bereits seit über 30 Jahren in der Optikbranche tätig. Mit dem Erlernen dieses Handwerks, entwickelte sich meine Leidenschaft für diesen Beruf und den Umgang mit Kunden.
Seit 2019 haben wir mit unserer Nichte Klara Wahl, als gelernte Optikerin, eine tolle Verstärkung im Team.<br /><br />
<strong>Optik ist unsere Passion, Ihre Zufriedenheit unser Ziel.</strong>
            </Typography>
            
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default SectionUeberUns;
