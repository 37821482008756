import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import blueGrey from "@material-ui/core/colors/blueGrey";
import { grey } from "@material-ui/core/colors";

export const THEME = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: "#243a6e",
        dark: "#1d3265"
      },
      secondary: {
        main: "#daad39",
        light: "#f8efd7"
      },
      background: {
        paper: "#fff",
        default: grey[50]
      }
    },
    typography: {
      fontFamily: "Merriweather Sans",
      h1: {
        fontWeight: 800,
        fontSize: "4.1rem",
        textTransform: "uppercase",
        letterSpacing: "-3px"
      },
      h2: {
        fontWeight: 800,
        fontSize: "3rem",
        textTransform: "uppercase",
        letterSpacing: "-1px"
      },
      h3: {
        fontWeight: 800,
        fontSize: "2.4rem",
        textTransform: "uppercase",
        letterSpacing: "-1px"
      },
      h4: {
        fontWeight: 500,
        fontSize: "1.7rem"
      },
      overline: {
        letterSpacing: 1
      },
      caption: {
        fontWeight: 500,
        fontSize: "0.9rem",
        color: grey[600]
      },
      body1: {
        fontSize: "1.4rem"
      }
    },
    shape: {
      borderRadius: 30
    },
    status: {
      danger: "orange"
    },
    overrides: {
      MuiButton: {
        root: {
          fontWeight: "bold"
        },
        outlined: {
          borderWidth: 2,
          borderColor: grey[800]
        },
        sizeLarge: {
          fontSize: "1.2rem"
        }
      }
    },
    props: {
      MuiButton: {
        disableFocusRipple: true
      },
      MuiTab: {
        disableFocusRipple: true
      },
      MuiIconButton: {
        disableFocusRipple: true
      }
    }
  })
);
