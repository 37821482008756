import { grey } from "@material-ui/core/colors";
import { makeStyles, Box, Typography, Button } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";

import ImgHandwerk from "../assets/btn_handwerk.jpg";
import ImgQualitaet from "../assets/btn_qualitaet.jpg";
import ImgService from "../assets/btn_service.jpg";
import Logo from "../assets/Logo_Wahl_Oktober21.png";
import Separator from "./shared/Separator";
import useScreensize from "../hooks/useScreensize";
import useWindowSize from "../hooks/useWindowSize";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  section_1: {
    height: "100vh",
    position: "relative",
    background: grey[800],
    opacity: 0,
    transition: "all 0.5s",
    [theme.breakpoints.down("xs")]: {
      height: "auto"
    }
  },
  section_1_visible: {
    opacity: 1
  },
  topBg: {
    position: "absolute",
    left: "50%",
    top: 0,
    right: 0,
    bottom: 0,
    background: grey[50],
    zIndex: 8,
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  img: {
    marginRight: theme.spacing(2),
    boxShadow: "0 0 10px rgba(0,0,0,0.4)",
    width: "100%",
    height: "auto",
    display: "block"
  },
  contentBox: {
    position: "absolute",
    left: "50%",
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 9,
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      left: 0,
      display: "block",
      background: "#fff",
      padding: theme.spacing(3, 2, 0, 2),
    }
  },
  logo: {
    width: 300,
    height: "auto",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  teaser: {
    fontWeight: 800,
    fontSize: "2rem",
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      lineHeight: 1.3,
      letterSpacing: -1
    }
  },
  imgBtn: {
    background: grey[700],
    color: "#fff",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    textDecoration: "none",
    marginRight: theme.spacing(2),
    display: "block",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.01)"
    },
  },
  imgBtnTop: {
    padding: theme.spacing(2),
  },
  imgBtnTxt: {
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12
    }
  },
}));
const SectionVideo = () => {
  const classes = useStyles();
  const windowSize = useWindowSize();
  const [videoSize, setVideoSize] = useState(null);
  const [videoWrapperSize, setVideoWrapperSize] = useState(null);
  const [sectionIsVisible, setSectionIsVisible] = useState(false);
  const { xs, sm, md, lg, xl } = useScreensize();

  useEffect(() => {
    setTimeout(() => {
      setSectionIsVisible(true);
    }, 2000);
    if (!windowSize || windowSize[0] === 0) {
      return;
    }
    const isLandscape = windowSize[0] > windowSize[1];

    if (xs || sm) {
      const wrapperWidth = windowSize[0];
      const wrapperHeight = wrapperWidth * 0.5625;

      const width = windowSize[0];
      const height = width * 0.5625;

      setVideoWrapperSize({ width: wrapperWidth, height: wrapperHeight });
      setVideoSize({ width, height });
      return;
    }

    if (isLandscape) {
      const wrapperHeight = windowSize[1];
      const wrapperWidth = windowSize[0];

      let height = wrapperHeight;
      let width = height / 0.5625;
      if (width < windowSize[0]) {
        width = windowSize[0];
        height = width * 0.5625;
      }

      setVideoWrapperSize({ width: wrapperWidth, height: wrapperHeight });
      setVideoSize({ width, height });
      return;
    }

    const wrapperHeight = windowSize[1] / 2.5;
    const wrapperWidth = windowSize[0];

    let height = wrapperHeight;
    let width = height / 0.5625;
    if (width < windowSize[0]) {
      width = windowSize[0];
      height = width * 0.5625;
    }
    if (width > windowSize[0]) {
      width = windowSize[0];
      height = width * 0.5625;
    }

    setVideoWrapperSize({ width: wrapperWidth, height: wrapperHeight });
    setVideoSize({ width, height });
  }, [windowSize]);

  if (!videoSize || !videoWrapperSize) {
    return null;
  }

  const mobileVideoWrapperStyle = {
    overflow: "hidden",
    left: 0,
    top: 0,
    zIndex: 1,
    width: "100%",
    height: videoWrapperSize.height,
    position: "relative",
    backgroundColor: grey[900]
  };

  const videoWrapperStyle = {
    overflow: "hidden",
    left: 0,
    top: 0,
    zIndex: 1,
    width: "100%",
    height: videoWrapperSize.height,
    position: "absolute",
    backgroundColor: grey[900]
  };

  const videoStyle = {
    position: "fixed",
    left: ((videoSize.width - videoWrapperSize.width) / 2) * -1,
    top: 0,
    width: videoSize.width,
    height: videoSize.height
  };

  const mobileVideoStyle = {
    position: "fixed",
    left: 0,
    top: 0,
    width: videoSize.width,
    height: videoSize.height
  };

  // const mobileVideo = xs && (
  //   <div className={classes.mobileVideoWrapper}>
  //     <iframe
  //       className={classes.mobileVideoFrame}
  //       title="Optik Wahl"
  //       src="https://player.vimeo.com/video/362673875"
  //       frameBorder="0"
  //       allow="autoplay; fullscreen"
  //       allowFullScreen
  //     ></iframe>
  //   </div>
  // );

  const mobileVideo = xs && (
    <div style={mobileVideoWrapperStyle}>
      <div className={classes.reactPlayerWrapper} style={mobileVideoStyle}>
        <ReactPlayer
          url="https://player.vimeo.com/video/571161168"
          playing
          loop
          volume={0}
          muted
          width="100%"
          height="100%"
          className="main-video"
        />
      </div>
    </div>
  );

  const desktopVideo = !xs && (
    <div style={videoWrapperStyle}>
      <div className={classes.reactPlayerWrapper} style={videoStyle}>
        <ReactPlayer
          url="https://player.vimeo.com/video/571161168"
          playing
          loop
          volume={0}
          muted
          width="100%"
          height="100%"
          className="main-video"
        />
      </div>
    </div>
  );

  return (
    <>
      <div className={clsx(classes.section_1, sectionIsVisible && classes.section_1_visible)}>
        {desktopVideo}
        {mobileVideo}
        <div className={classes.topBg}></div>
        <Box
          pl={12}
          pr={6}
          pb={0}
          pt={5}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
          height="100%"
          className={classes.contentBox}
        >
          <img
            src={Logo}
            alt="Der optiker Stefan Wahl"
            className={classes.logo}
          />
          <Typography variant="h1">
            Optik ist unsere
            <br />
            Leidenschaft.
          </Typography>
          <Box width="40%" ml={-12} my={{xs: "20px", md: 0}}>
            <Separator />
          </Box>
          <Box mb={{xs: "30px", md: 0}}>
            <Typography className={classes.teaser} variant="body1">
              Wir bieten mehr als eine Fassung<br />mit zwei Gläsern.
            </Typography>
          </Box>
          <Button href="mailto:info@stefanwahl.de" variant="outlined" size="large">
            Kontaktieren Sie uns
          </Button>
          <Box display="flex" mt={{xs: "30px", md: 0}} justifyContent={{xs: "space-between", md: "flex-start"}}>
            <a href="#qualitaet" className={classes.imgBtn}>
              <div className={classes.imgBtnTop}>
                <Typography variant="h4" className={classes.imgBtnTxt}>Qualität</Typography>
              </div>
              <img src={ImgQualitaet} alt="" className={classes.img} />
            </a>
            <a href="#handwerk" className={classes.imgBtn}>
              <div className={classes.imgBtnTop}>
                <Typography variant="h4" className={classes.imgBtnTxt}>Handwerk</Typography>
              </div>
              <img src={ImgHandwerk} alt="" className={classes.img} />
            </a>
            <a href="#service" className={classes.imgBtn} style={{marginRight: 0}}>
              <div className={classes.imgBtnTop}>
                <Typography variant="h4" className={classes.imgBtnTxt}>Service</Typography>
              </div>
              <img src={ImgService} alt="" className={classes.img} />
            </a>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default SectionVideo;
