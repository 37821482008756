import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React, { useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import WaSlider from "./shared/WaSlider";
import Slide1 from "../assets/Schritt1_wahl.jpg";
import Slide2 from "../assets/Schritt2_wahl.jpg";
import Slide3 from "../assets/Schritt3_wahl.jpg";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    zIndex: 1,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    background: "#353535",
    [theme.breakpoints.up("md")]: {
      minHeight: "100vh",
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(15),
    }
  },
  title: {
    width: "100%",
    color: "#fff",
    [theme.breakpoints.up("md")]: {
      width: "130%",
    }
  },
  mainText: {
    color: "#fff"
  },
  arrow: {
    width: 40,
    height: 40
  },
  slideImg: {
    width: "100%",
    height: "auto"
  }
}));

const images = [Slide1, Slide2, Slide3, Slide1, Slide2, Slide3];
const texts = [
  {
    title: "SCHRITT 1: 3D-AUGENREFRAKTION",
    text: "Wir können nur dann die richtigen Brillengläser für Sie auswählen, wenn wir über ausreichend Informationen zu Ihren Augen und Ihrem Sehen verfügen. Mit der 3D-Augenrefraktion erstellen wir ein exaktes Profil der Sehleistung Ihrer Augen."
  },
  {
    title: "SCHRITT 2: ANPASSUNG AN IHRE INDIVIDUELLE PHYSIOGNOMIE",
    text: "Perfekte Brillengläser alleine reichen aber noch nicht für perfektes Sehen. Mit modernster Technik und Know-How ermitteln wir daher die physischen Proportionen und Ihren Augenabstand und passen so Ihre Brille individuell für Sie an. "
  },
  {
    title: "SCHRITT 3: LANGE SEH-FREUDE",
    text: "Bei der Übergabe Ihrer neuen Brille wird diese nochmals exakt angepasst und justiert. Somit bietet Ihr neuer Begleiter im täglichen Einsatz bestes Sehen und perfekten Tragekomfort. Natürlich sind wir auch bei der späteren Pflege Ihrer Brille für Sie da – sei es beim Nachjustieren oder eventuellen Reparaturen."
  },
  {
    title: "SCHRITT 1: 3D-AUGENREFRAKTION",
    text: "Wir können nur dann die richtigen Brillengläser für Sie auswählen, wenn wir über ausreichend Informationen zu Ihren Augen und Ihrem Sehen verfügen. Mit der 3D-Augenrefraktion erstellen wir ein exaktes Profil der Sehleistung Ihrer Augen."
  },
  {
    title: "SCHRITT 2: ANPASSUNG AN IHRE INDIVIDUELLE PHYSIOGNOMIE",
    text: "Perfekte Brillengläser alleine reichen aber noch nicht für perfektes Sehen. Mit modernster Technik und Know-How ermitteln wir daher die physischen Proportionen und Ihren Augenabstand und passen so Ihre Brille individuell für Sie an. "
  },
  {
    title: "SCHRITT 3: LANGE SEH-FREUDE",
    text: "Bei der Übergabe Ihrer neuen Brille wird diese nochmals exakt angepasst und justiert. Somit bietet Ihr neuer Begleiter im täglichen Einsatz bestes Sehen und perfekten Tragekomfort. Natürlich sind wir auch bei der späteren Pflege Ihrer Brille für Sie da – sei es beim Nachjustieren oder eventuellen Reparaturen."
  },
];

const SectionSteps = () => {
  const classes = useStyles();
  const [slideIndex, setSlideIndex] = useState({ from: 0, to: 0 });

  const next = () => {
    let { to } = slideIndex;
    const fromNew = to;
    if (to >= imageSlides.length - 1) {
      to = 0;
    } else {
      to += 1;
    }
    setSlideIndex({ from: fromNew, to });
  };

  const prev = () => {
    let { to } = slideIndex;
    const fromNew = to;
    if (to <= 0) {
      to = imageSlides.length - 1;
    } else {
      to -= 1;
    }
    setSlideIndex({ from: fromNew, to });
  };

  const imageSlides = images.map((img, i) => (
    <img src={img} alt="" className={classes.slideImg} />
  ));

  const textSlides = texts.map((t, i) => (
    <React.Fragment key={i}>
      <Typography variant="h3">{t.title}</Typography>
      <Box mt={2}>
        <Typography variant="body1">{t.text}</Typography>
      </Box>
    </React.Fragment>
  ));

  return (
    <div className={classes.wrapper} id="steps">
      <Box display="flex" flexDirection={{xs: "column", md: "row"}}>
        <Box width={{xs: "100%", md: "45%"}} pl={{xs: 6, md: "13vw"}} py={{xs: 3, md: 10}} pr={6} zIndex={9}>
          <Typography variant="h1" component="h2" className={classes.title}>
            In drei Schritten zum perfekten (Aus)sehen
          </Typography>
          <Box mt={3} color="white">
            <Typography variant="body1" className={classes.mainText}>
            Wir nehmen uns Zeit für Sie und Ihre neue Brille.
Denn eine Brille ist mehr als eine Sehhilfe.
In drei Schritten helfen wir Ihnen zum perfekten 
Sehen und finden dabei den idealen Look Ihres
neuen „Begleiters“.
            </Typography>
            
          </Box>
        </Box>
        <Box width={{xs: "100%", md: "55%"}} overflow="hidden">
          <Box width="120%">
            <WaSlider
              slideIndex={slideIndex}
              slides={imageSlides}
              slidesToShow={3}
            />
          </Box>
          <Box width="90%" color="#fff">
            <WaSlider
              slideIndex={slideIndex}
              slides={textSlides}
              slidesToShow={1}
            />
            <Box>
              <IconButton color="inherit" onClick={prev}>
                <ArrowBackIcon className={classes.arrow} />
              </IconButton>
              <IconButton color="inherit" onClick={next}>
                <ArrowForwardIcon className={classes.arrow} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default SectionSteps;
