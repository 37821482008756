import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React, { useRef, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import WaSlider from "./shared/WaSlider";
import Slide1 from "../assets/service/1.jpg";
import Slide2 from "../assets/service/2.jpg";
import Slide3 from "../assets/service/3.jpg";
import Slide4 from "../assets/service/4.jpg";
import Slide5 from "../assets/service/5.jpg";
import Slide6 from "../assets/service/6.jpg";
import ReactPlayer from "react-player";
import useScreensize from "../hooks/useScreensize";
import { useIsVisible } from "react-is-visible";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    zIndex: 1,
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    background: "#353535",
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    }
  },
  title: {
    width: "100%",
    color: "#fff",
    [theme.breakpoints.up("md")]: {
      width: "130%",
    }
  },
  mainText: {
    color: "#fff"
  },
  arrow: {
    width: 40,
    height: 40
  },
  slideImg: {
    width: "100%",
    height: "auto"
  },
  slideVideo: {
    width: "100%",
    height: 0,
    paddingBottom: "66.65%",
    boxSizing: "content-box",
    position: "relative",
    overflow: "hidden"
  },
  reactPlayer: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    transform: "scale(1.2)"
  }
}));

const images = [
  {type: "video", src: "639864551"},
  {type: "img", src: Slide1},
  {type: "img", src: Slide2},
  {type: "img", src: Slide3},
  {type: "img", src: Slide4},
  {type: "img", src: Slide5},
  {type: "img", src: Slide6},
  {type: "video", src: "639864551"},
  {type: "img", src: Slide1},
  {type: "img", src: Slide2},
  {type: "img", src: Slide3},
  {type: "img", src: Slide4},
  {type: "img", src: Slide5},
  {type: "img", src: Slide6}
];
const texts = [];

const SectionService = () => {
  const classes = useStyles();
  const [slideIndex, setSlideIndex] = useState({ from: 0, to: 0 });
  const { xs, sm, md, lg, xl } = useScreensize();
  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);

  const next = () => {
    let { to } = slideIndex;
    const fromNew = to;
    if (to >= imageSlides.length - 1) {
      to = 0;
    } else {
      to += 1;
    }
    setSlideIndex({ from: fromNew, to });
  };

  const prev = () => {
    let { to } = slideIndex;
    const fromNew = to;
    if (to <= 0) {
      to = imageSlides.length - 1;
    } else {
      to -= 1;
    }
    setSlideIndex({ from: fromNew, to });
  };

  const imageSlides = images.map((img, i) => {
    if (img.type === "img") {
      return <img src={img.src} alt="" className={classes.slideImg} />
    }
    if (img.type === "video") {
      return (
        <div className={classes.slideVideo}>
          <div className={classes.reactPlayer}>
            <ReactPlayer
              url={"https://player.vimeo.com/video/" + img.src}
              playing={isVisible}
              loop
              volume={0}
              muted
              width="100%"
              height="100%"
              className="main-video"
            />
          </div>
        </div>
      );
    }
  });

  const textSlides = texts.map((t, i) => (
    <React.Fragment key={i}>
      <Typography variant="h3">{t.title}</Typography>
      <Box mt={2}>
        <Typography variant="body1">{t.text}</Typography>
      </Box>
    </React.Fragment>
  ));

  return (
    <div ref={nodeRef} className={classes.wrapper} id="service">
      <Box display={xs || sm ? "block" : "flex"}>
        <Box width={xs || sm ? "100%" : "45%"} pl={{xs: 6, md: "13vw"}} pr={6} pt={{xs: 3, md: 0}} zIndex={9} color="white">
          <Typography variant="h1" component="h2" className={classes.title} gutterBottom>
            Service
          </Typography>
          <Typography variant="h3">
            Für uns stehen Sie stets an erster Stelle.
          </Typography>
          <Box mt={3}>
            <Typography variant="body1" className={classes.mainText}>
            Unser Team setzt auf fundierte fachliche Beratung. Mittels moderner 3D Technik, bieten wir Ihnen eine Augenglasbestimmung (Augenrefraktion) in präziser Qualität. Bei uns kaufen Sie nicht einfach eine Brille, wir stehen Ihnen mit umfassenden Serviceleistungen zur Verfügung.
            </Typography>
            
          </Box>
        </Box>
        <Box width={xs || sm ? "100%" : "55%"} overflow="hidden">
          <Box width="120%">
            <WaSlider
              slideIndex={slideIndex}
              slides={imageSlides}
              slidesToShow={2}
            />
          </Box>
          <Box width="90%" color="#fff">
            <WaSlider
              slideIndex={slideIndex}
              slides={textSlides}
              slidesToShow={1}
            />
            <Box>
              <IconButton color="inherit" onClick={prev}>
                <ArrowBackIcon className={classes.arrow} />
              </IconButton>
              <IconButton color="inherit" onClick={next}>
                <ArrowForwardIcon className={classes.arrow} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default SectionService;
