import * as React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box } from "@material-ui/core";

export default function PopupHint() {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box p={2}>{"Hinweis"}</Box>
        </DialogTitle>
        <DialogContent>
          <Box p={2}>
            <DialogContentText id="alert-dialog-description">
              Bitte beachten Sie, dass unser Ladengeschäft vom 16.10.2024 bis 23.10.2024 geschlossen ist.
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box p={2}>
            <Button onClick={handleClose} autoFocus>
              Schließen
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
