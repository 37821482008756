import { Box } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  dark: {
    background: theme.palette.secondary.main
  },
  light: {
    background: theme.palette.secondary.light
  }
}));

const Separator = () => {
  const classes = useStyles();

  return (
    <>
      <Box width="100%" className={classes.light}>
        <Box width="100%" className={classes.dark} height={4}></Box>
      </Box>
    </>
  );
};

export default Separator;
