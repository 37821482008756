import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";

import Img1 from "../assets/01_optovision.png";
import Img2 from "../assets/02_pieromassaro.png";
import Img4 from "../assets/04_chopard.png";
import Img5 from "../assets/05_goetti.png";
import Img6 from "../assets/06_cutlerandcross.png";
import Img7 from "../assets/07_rudyproject.png";
import Img9 from "../assets/09_oscarmagnuson.png";
import Img10 from "../assets/10_stevemcqueen.png";
import Img12 from "../assets/12_prada.png";
import Img15 from "../assets/15_exalto.png";
import Img20 from "../assets/20_cocosong.png";
import Img21 from "../assets/Logo_TREE.png";
import Img22 from "../assets/Logo_Brett.png";
import Separator from "./shared/Separator";
import WaSlider from "./shared/WaSlider";

const images = [
  Img1,
  Img2,
  Img4,
  Img5,
  Img6,
  Img7,
  Img9,
  Img10,
  Img12,
  Img21,
  Img15,
  Img22,
  Img20
];
const itemsPerSlide = 8;

const SectionPartner = () => {
  const imgSlides = Array.from(
    { length: Math.ceil(images.length / itemsPerSlide) },
    (_, i) => {
      console.log(i);
      return images.slice(i * itemsPerSlide, i * itemsPerSlide + itemsPerSlide);
    }
  ).map((images, idx) => (
    <Grid container key={idx}>
      {images.map((img, i) => (
        <Grid item key={i} md={3}>
          <Box p={8}>
            <img src={img} alt="" style={{ width: "100%", height: "auto" }} />
          </Box>
        </Grid>
      ))}
    </Grid>
  ));
  console.log(imgSlides);

  return (
    <div style={{ zIndex: 9, position: "relative" }} id="partner">
      <Box px="13vw" py={10} bgcolor="#fff">
        <Typography variant="h1" component="h2">
          Unsere Partner & Marken
        </Typography>
        <Box width="40%" ml="-13vw" my={{ xs: 2, lg: 6 }}>
          <Separator />
        </Box>
        <Box mx={-8} width={{ xs: "100vw", lg: "auto" }}>
          <WaSlider
            slides={imgSlides}
            slidesToShow={1}
            autoPlay={true}
            speed={2000}
            dots={true}
          />
        </Box>
      </Box>
    </div>
  );
};

export default SectionPartner;
