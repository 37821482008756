import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React, { useRef, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import WaSlider from "./shared/WaSlider";
import Slide1 from "../assets/handwerk/1.jpg";
import Slide2 from "../assets/handwerk/2.jpg";
import Slide3 from "../assets/handwerk/3.jpg";
import Slide4 from "../assets/handwerk/4.jpg";
import Slide5 from "../assets/handwerk/5.jpg";
import ReactPlayer from "react-player";
import useScreensize from "../hooks/useScreensize";
import { useIsVisible } from "react-is-visible";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    zIndex: 1,
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    background: grey[200],
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    }
  },
  title: {
    width: "100%",
    color: grey[900],
    [theme.breakpoints.up("md")]: {
      width: "130%",
    }
  },
  subtitle: {
    color: grey[900]
  },
  mainText: {
    color: grey[900]
  },
  arrow: {
    width: 40,
    height: 40
  },
  slideImg: {
    width: "100%",
    height: "auto"
  },
  slideVideo: {
    width: "100%",
    height: 0,
    paddingBottom: "66.65%",
    boxSizing: "content-box",
    position: "relative",
    overflow: "hidden"
  },
  reactPlayer: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    transform: "scale(1.2)"
  }
}));

const images = [
  
  {type: "img", src: Slide1},
  {type: "video", src: "639866190"},
  {type: "img", src: Slide2},
  {type: "img", src: Slide3},
  {type: "img", src: Slide4},
  {type: "img", src: Slide5},
  {type: "img", src: Slide1},
  {type: "video", src: "639866190"},
  {type: "img", src: Slide2},
  {type: "img", src: Slide3},
  {type: "img", src: Slide4},
  {type: "img", src: Slide5}
];
const texts = [];

const SectionHandwerk = () => {
  const classes = useStyles();
  const [slideIndex, setSlideIndex] = useState({ from: 0, to: 0 });
  const { xs, sm, md, lg, xl } = useScreensize();
  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);

  const next = () => {
    let { to } = slideIndex;
    const fromNew = to;
    if (to >= imageSlides.length - 1) {
      to = 0;
    } else {
      to += 1;
    }
    setSlideIndex({ from: fromNew, to });
  };

  const prev = () => {
    let { to } = slideIndex;
    const fromNew = to;
    if (to <= 0) {
      to = imageSlides.length - 1;
    } else {
      to -= 1;
    }
    setSlideIndex({ from: fromNew, to });
  };

  const imageSlides = images.map((img, i) => {
    if (img.type === "img") {
      return <img src={img.src} alt="" className={classes.slideImg} />
    }
    if (img.type === "video") {
      return (
        <div className={classes.slideVideo}>
          <div className={classes.reactPlayer}>
            <ReactPlayer
              url={"https://player.vimeo.com/video/" + img.src}
              playing={isVisible}
              loop
              volume={0}
              muted
              width="100%"
              height="100%"
              className="main-video"
            />
          </div>
        </div>
      );
    }
  });

  const textSlides = texts.map((t, i) => (
    <React.Fragment key={i}>
      <Typography variant="h3">{t.title}</Typography>
      <Box mt={2}>
        <Typography variant="body1">{t.text}</Typography>
      </Box>
    </React.Fragment>
  ));

  return (
    <div ref={nodeRef} className={classes.wrapper} id="handwerk">
      <Box display={xs || sm ? "block" : "flex"}>
        
        <Box width={xs || sm ? "100%" : "55%"} overflow="hidden">
          <Box width="120%" style={{marginLeft: "-20%"}}>
            <WaSlider
              slideIndex={slideIndex}
              slides={imageSlides}
              slidesToShow={2}
            />
          </Box>
          <Box width="100%">
            <WaSlider
              slideIndex={slideIndex}
              slides={textSlides}
              slidesToShow={1}
            />
            <Box textAlign="right">
              <IconButton color="inherit" onClick={prev}>
                <ArrowBackIcon className={classes.arrow} />
              </IconButton>
              <IconButton color="inherit" onClick={next}>
                <ArrowForwardIcon className={classes.arrow} />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box width={xs || sm ? "100%" : "45%"} pr={{xs: 6, md: "13vw"}} pl={6} zIndex={9}>
          <Typography variant="h1" component="h2" className={classes.title} gutterBottom>
            Handwerk
          </Typography>
          <Typography variant="h3" className={classes.subtitle}>
            Wir verstehen unser Handwerk. Und unsere Kunden.
          </Typography>
          <Box mt={3}>
            <Typography variant="body1" className={classes.mainText}>
            In unserer eigenen Optikwerkstatt werden die Gläser präzise geschliffen und geprüft. Jede Brille wird individuell für Sie angepasst und verglast.
            </Typography>
            
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default SectionHandwerk;
