import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  absatz: {
    marginBottom: theme.spacing(3),
    lineHeight: 1.6
  }
}));

const Datenschutz = () => {
  const classes = useStyles();

  return (
    <>
      <Box mx="auto" maxWidth="1024px" py={10} bgcolor={grey[50]}>
        <Typography variant="h3" component="h1" gutterBottom>Datenschutz</Typography>

        <Typography variant="body2" gutterBottom className={classes.absatz}>
        Erklärung zum Datenschutz im Sinne des Art. 13 der DSGVO: Stand 18.05.2018
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>Verantwortliche Stelle</Typography>
        
        <Typography variant="body2" gutterBottom className={classes.absatz}>
        Die verantwortliche Stelle ist über die folgende Anschrift erreichbar:<br />
        Der Optiker Stefan Wahl, Karl-Marx-Str. 7, 67655-Kaiserslautern, Tel.: 0631-89290698
        </Typography>
        
        <Typography variant="h5" component="h2" gutterBottom>Protokollierung der Aufrufe der Website</Typography>
        
        <Typography variant="body2" gutterBottom className={classes.absatz}>
        Aus Gründen der Sicherheit und als Hilfsmittel für die Wartung der Website ergibt sich für den Betreiber neben der rechtlichen Notwendigkeit, z.B. im Rahmen der Beweisführung bei Missbrauch, ein berechtigtes Interesse (nach Art. 6 Abs. 1 DSGVO) für die Protokollierung aller Aufrufe dieser Website.
        In Abhängigkeit der für den Auruf verwendeten WebBrowser-Software, werden verschiedene Parameter erfasst. Darunter können folgende Informationen sein, welche unter Umständen in die Kategorie personenbezogener Daten fallen:
        </Typography>

        <Typography variant="body2" gutterBottom className={classes.absatz}>
          <ul>
            <li>Datum und Uhrzeit sowie IP-Adresse des Internetzugangs von welchem aus der Aufruf erfolgt</li>
            <li>Hinweise zur Software über welche der Aufruf erfolgt (Hersteller und Version des Browsers, Hersteller und Version des Betriebssystems)</li>
            <li>Name des PCs, welcher der Aufruf initiiert hat</li>
          </ul>
        </Typography>
        
        <Typography variant="body2" gutterBottom className={classes.absatz}>
        Die hierbei erzeugten Protokolle werden max. 365 Tage gespeichert. Diese Daten werden nicht an Dritte übermittelt.
        </Typography>
        
        <Typography variant="h5" component="h2" gutterBottom>Kontaktformular</Typography>
        
        <Typography variant="body2" gutterBottom className={classes.absatz}>
        Sollten Sie ein von uns hier bereitgestelltes Kontaktformular nutzen, um uns eine Nachricht zukommen zu lassen, willigen Sie der sich daraus zwingend ergebenden Speicherung dieser Daten ein. Die Übermittelung dieser Daten von Ihrem Browser an unseren Server, erfolgt verschlüsselt, gemäß dem aktuellem Stand der Technologie und den Fähigkeiten Ihrer Browser-Software. Ihre Daten werden für die Bearbeitung Ihrer Anfrage genutzt und nicht an Dritte übermittelt. Recht auf Auskunft, Korrektur und Löschung Als von der Speicherung personenbezogener Daten Betroffener, können Sie sich schriftlich an die oben genannte verantwortliche Stelle wenden, um Informationen über diese Daten zu erhalten und ggf. deren Korrektur oder Löschung anzufordern.
        </Typography>
      </Box>
    </>
  );
};

export default Datenschutz;
