import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React, { useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import WaSlider from "./shared/WaSlider";
import Slide1 from "../assets/innovation/1.jpg";
import Slide2 from "../assets/innovation/2.jpg";
import Slide4 from "../assets/innovation/4.jpg";
import useScreensize from "../hooks/useScreensize";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    zIndex: 1,
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    background: grey[200],
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    }
  },
  title: {
    width: "100%",
    color: grey[900],
    [theme.breakpoints.up("md")]: {
      width: "130%",
    }
  },
  subtitle: {
    color: grey[900]
  },
  mainText: {
    color: grey[900]
  },
  arrow: {
    width: 40,
    height: 40
  },
  slideImg: {
    width: "100%",
    height: "auto"
  }
}));

const images = [Slide1, Slide2, Slide4, Slide1, Slide2, Slide4];
const texts = [];

const SectionInnovation = () => {
  const classes = useStyles();
  const [slideIndex, setSlideIndex] = useState({ from: 0, to: 0 });
  const { xs, sm, md, lg, xl } = useScreensize();

  const next = () => {
    let { to } = slideIndex;
    const fromNew = to;
    if (to >= imageSlides.length - 1) {
      to = 0;
    } else {
      to += 1;
    }
    setSlideIndex({ from: fromNew, to });
  };

  const prev = () => {
    let { to } = slideIndex;
    const fromNew = to;
    if (to <= 0) {
      to = imageSlides.length - 1;
    } else {
      to -= 1;
    }
    setSlideIndex({ from: fromNew, to });
  };

  const imageSlides = images.map((img, i) => (
    <img src={img} alt="" className={classes.slideImg} />
  ));

  const textSlides = texts.map((t, i) => (
    <React.Fragment key={i}>
      <Typography variant="h3">{t.title}</Typography>
      <Box mt={2}>
        <Typography variant="body1">{t.text}</Typography>
      </Box>
    </React.Fragment>
  ));

  return (
    <div className={classes.wrapper} id="innovation">
      <Box display={xs || sm ? "block" : "flex"}>
        <Box width={xs || sm ? "100%" : "55%"} overflow="hidden">
          <Box width="120%" style={{marginLeft: "-20%"}}>
            <WaSlider
              slideIndex={slideIndex}
              slides={imageSlides}
              slidesToShow={2}
            />
          </Box>
          <Box width="100%">
            <WaSlider
              slideIndex={slideIndex}
              slides={textSlides}
              slidesToShow={1}
            />
            <Box textAlign="right">
              <IconButton color="inherit" onClick={prev}>
                <ArrowBackIcon className={classes.arrow} />
              </IconButton>
              <IconButton color="inherit" onClick={next}>
                <ArrowForwardIcon className={classes.arrow} />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box width={xs || sm ? "100%" : "45%"} pr={{xs: 6, md: "13vw"}} pl={6} zIndex={9}>
          <Typography variant="h1" component="h2" className={classes.title} gutterBottom>
            Innovation
          </Typography>
          <Box mt={3}>
            <Typography variant="body1" className={classes.mainText}>
            Interaktive Darstellungen ermöglichen es dem Betrachter, unterschiedliche Brillenglasvarianten und - technologien schon vor dem Kauf in verschiedensten Alltagssituationen zu testen und zu vergleichen.
Ganz unabhängig davon, ob man eine Brille zum Autofahren, eine Sonnenbrille mit polarisierenden Gläsern oder eine Brille sucht, die speziell auf
den individuellen Arbeitsplatz abgestimmt ist.<br />
Am Ende der Beratung verfügt der Kunde über das nötige Wissen, was bei der Brillenglasvielfalt alles möglich ist und erhält damit die Sicherheit, die beste Brille für die eigenen Belange ausgesucht zu haben.
            </Typography>
            
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default SectionInnovation;
