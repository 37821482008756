import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import grey from "@material-ui/core/colors/grey";

import Logo from "../assets/FooterLogo_Wahl_Oktober21.png";
import Team from "../assets/wahl_footer.jpg";
import DerBeste from "../assets/siegel_der_beste.png";

const Footer = () => {
  return (
    <>
      <Box
        px={{xs: 6, md: "13vw"}}
        py={8}
        bgcolor={grey[800]}
        color="white"
        zIndex={9}
        position="relative"
      >
        <Grid container>
          <Grid item md={3}>
            <Box pr={{xs: 0, md: 5}} pb={{xs: 5, md: 0}}>
              <img
                src={Team}
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box pr={5}>
              <Grid container>
                <Grid item md={6}>
                  <img
                    src={Logo}
                    alt=""
                    style={{ width: "100%", height: "auto" }}
                  />
                  </Grid>
                </Grid>
                <Box mt={2}>
                  <Typography>
                    Karl-Marx-Str. 7<br />
                    67655 Kaiserslautern
                    <br />
                    0631 - 89290698
                    <br />
                    <a style={{textDecoration: "none", color: "#fff"}} href="mailto:info@stefanwahl.de">info@stefanwahl.de</a>
                  </Typography>
                </Box>
              
            </Box>
            <Box mt={4}>
              <Typography>
                Öffnungszeiten:
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography component="div">
                <table style={{width: "100%"}}>
                  <tbody>
                    <tr style={{verticalAlign: "top"}}>
                      <td>Montag</td>
                      <td>09:30–13:00, 14:00–18:00</td>
                    </tr>
                    <tr style={{verticalAlign: "top"}}>
                      <td>Dienstag</td>
                      <td>09:30–13:00, 14:00–18:00</td>
                    </tr>
                    <tr style={{verticalAlign: "top"}}>
                      <td>Mittwoch</td>
                      <td>Geschlossen</td>
                    </tr>
                    <tr style={{verticalAlign: "top"}}>
                      <td>Donnerstag</td>
                      <td>09:30–13:00, 14:00–18:00</td>
                    </tr>
                    <tr style={{verticalAlign: "top"}}>
                      <td>Freitag</td>
                      <td>09:30–13:00, 14:00–18:00</td>
                    </tr>
                    <tr style={{verticalAlign: "top"}}>
                      <td>Samstag</td>
                      <td>09:30–14:00</td>
                    </tr>
                  </tbody>
                </table>
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box pr={{xs: 0, md: 5}} pt={{xs: 5, md: 0}}>
            <img
                src={DerBeste}
                alt=""
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Footer;
